'use strict';

var core = require('core/login/login');

core.reinit = function reinit() {
    $('body').on('login:reinit', () => { // arrow function used to keep `this`
        this.login();
        this.register();
        this.resetPassword();
    });
}

module.exports = core;